export function RecipeCardInFeedAd() {
    return (
        <>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6004231239349931"
                crossOrigin="anonymous"></script>
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-format="fluid"
                data-ad-layout-key="-6t+d5-27+4v+al"
                data-ad-client="ca-pub-6004231239349931"
                data-ad-slot="7056881482"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
            </script>
        </>
    )
}